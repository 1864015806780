<template>

    <div class="col-12 col-md-6 d-flex">
        <div class="card br-10 p-2 w-100">
            <div class="card-body">
                
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <p class="mb-1">{{ state.housework.name }}</p>
                        <p class="mb-0 dg-2 fs-08">
                            <span class="badge badge-light me-1" v-if="state.housework.room">
                                <i class="fa-regular fa-location-dot fa-fw me-1"></i>{{ state.housework.room.name }}
                            </span>
                            <span class="badge badge-light me-1" v-if="state.housework.duration">
                                <i class="fa-regular fa-timer me-1"></i>{{ state.housework.duration }} min
                            </span>
                        </p>
                        <p class="mb-0 fst-italic fs-08 mt-1">
                            <span class="dg-1 fade-in">{{ state.housework.lastActionString }}</span>
                        </p>
                    </div>
                    <div class="d-flex flex-column">
                        <i 
                            v-if="!state.houseworkValidationLoader"
                            @click="validateHousework(state.housework['@id'])"
                            class="fa-regular fa-circle-check blue-2 cursor fs-4"
                        ></i>
                        <i 
                            v-else
                            class="fa-regular fa-spinner fa-spin blue-2 fs-4"
                        ></i>

                        <i 
                            v-if="state.housework.description || ( state.housework.products && state.housework.products.length > 0 )"
                            class="fa-light fa-circle-info dg-1 cursor fs-4 mt-2"
                            data-bs-toggle="collapse" 
                            :href="`#collapse_${state.housework.id}`" 
                            role="button" 
                            aria-expanded="false" 
                            :aria-controls="`collapse_${state.housework.id}`"
                        ></i>
                    </div>
                </div>

                <!-- More info ? -->
                <div 
                    class="collapse" 
                    :id="`collapse_${state.housework.id}`"
                >
                    <hr class="my-3">
                    <div v-if="state.housework.description">
                        <p class="fw-bold mb-0">Description</p>
                        <p 
                            class="dg-2 fs-08" 
                            v-html="state.housework.description"
                        ></p>
                    </div>
                    <div v-if="state.housework.products && state.housework.products.length > 0">
                        <p class="fw-bold mb-0">Produits</p>
                        <div class="">
                            <span 
                                v-for="product in state.housework.products"
                                class="badge rounded-pill badge-primary me-1"
                            >
                                {{ product.name }}
                            </span>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
    </div>
        
</template>

<script setup>

    import { watchEffect, reactive } from "vue";
    import axios from 'axios'

    /*
        🏷️ Propriétés 
    */
    const props = defineProps({
        houseworkId: Number
    })

    const state = reactive({
        housework: [],
        houseworkWithMoreInfos: [],
        houseworkValidationLoader: false,
        houseworkDone: ''
    });


    /*
        1️⃣ Initialisation 
    */
    watchEffect(() => {        
        axios.get('/api/houseworks/' + props.houseworkId)
            .then(response => {
                state.housework = response.data
                getLastRealisation();
            });
    })


    /*
        💪 Méthodes 
    */
    // Get de la dernière fois que la tâche a été réalisée ( max 1 mois avant )
    function getLastRealisation() {
        axios.get('/api/houseworks_dones?order[createdAt]=desc&housework='+ state.housework.id +'&createdAt[after]=' + getDayXMonthBefore(1))
            .then(response => {

                // Find, renvoi la première valeure seulement
                state.houseworkDone = response.data['hydra:member'].find(houseworkDone => houseworkDone.housework.id == state.housework.id );

                if (state.houseworkDone) {
                    state.housework.lastActionDate = state.houseworkDone.createdAt;
                    state.housework.lastActionString = showDateInGoodFormat(state.houseworkDone.createdAt);
                } else {
                    state.housework.lastActionString = "Il y a plus d'un mois !"
                }
               
            });
    }

    // 📆 Détermine une date, X mois avant aujourd'hui
    function getDayXMonthBefore(nbOfMonth) {
        // One month before (# l'enfer des dates en JS )
        let d = new Date();
        d.setMonth(d.getMonth() - nbOfMonth);
        return `${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? '0' + ( d.getMonth() + 1 ) : ( d.getMonth() + 1 )}-${d.getDate() < 10 ? '0' + d.getDate() : d.getDate()}`
    }

    // ✅ Valider une tâche
    function validateHousework(iri) {
        state.houseworkValidationLoader = true;
        axios.post('/api/houseworks_dones', {
            housework: iri
        })
        .then(response => {
            console.log('Housework validate ✅');
            // Je modifie, visuelement l'affichage de la date grace au rteour de l'appel API
            state.housework.lastActionDate = response.data.createdAt;
            state.housework.lastActionString = showDateInGoodFormat(response.data.createdAt);
            state.houseworkValidationLoader = false;
        })
    }

    // Boucle de vérification de la dernière action 
    setInterval(() => {

        let validationDate = new Date(state.housework.lastActionDate);
        let today = new Date();

        let diff = dateDiff(validationDate, today);

        // ( refresh que si effectuée dans la journée pour éviter trop de requêtes )
        if (diff.day < 1) {
            getLastRealisation();
        }
        
    }, 10000);


    // ❤️❤️❤️ Mise en forme de la date de validation de la tâche
    function showDateInGoodFormat(date) {

        let validationDate = new Date(date);
        let today = new Date();

        let diff = dateDiff(validationDate, today);

        //console.log(diff);

        // Aujourd hui
        if (diff.day < 1) {

            if (diff.hour < 1 && diff.min < 1) {
                return "À l'instant !" 
            } else if (diff.hour < 1) {
                if (diff.min == 1) {
                    return "Il y a " + diff.min + " minute";
                } else {
                    return "Il y a " + diff.min + " minutes";
                }
            } else {

                let message = 'Il y a ';

                if (diff.hour == 1) {
                    message += diff.hour + " heure et ";
                } else {
                    message += diff.hour + " heures et ";
                }

                if (diff.min == 1) {
                    message += diff.min + " minute";
                } else {
                    message += diff.min + " minutes";
                }

                return message;
            }
            
        // Hier
        } else if (diff.day == 1) {
            return `Hier, à ${validationDate.getHours()}h${validationDate.getMinutes()}min`
            
        // Il y a x jours ( moins d'un mois )
        } else if (diff.day > 1) {
            return 'Il y a ' + diff.day + ' jours'

        // Plus d'un mois
        } else {
            return "Il y a plus d'un mois !"
        }
    }

    // ❤️❤️❤️ Méthode piqué quelque part paemttant de calculer la différence netre 2 date en JS et retorunant un objet détaillé avec Minutes, Heures...
    function dateDiff(date1, date2){
        let diff = {}                           // Initialisation du retour
        let tmp = date2 - date1;
    
        tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
        diff.sec = tmp % 60;                    // Extraction du nombre de secondes
    
        tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
        diff.min = tmp % 60;                    // Extraction du nombre de minutes
    
        tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
        diff.hour = tmp % 24;                   // Extraction du nombre d'heures
        
        tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
        diff.day = tmp;
        
        return diff;
    }

</script>

<style>
    .fade-in {
        animation: fadeIn .1s;
    }

    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
</style>
