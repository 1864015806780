<template>
  <div class="mb-3 d-flex align-items-center gap-2">
    <span>Essai en dehors du calendar</span>
    <button class="btn btn-primary" @click="prev">Prev</button>
    <button class="btn btn-primary" @click="next">Next</button>
  </div>
  <FullCalendar ref="fullCalendar" :options="calendarOptions">
    <template v-slot:resourceLabelContent='arg'>
      <div class="d-flex gap-2 p-1">
        <span class="color-employee" :style="{ backgroundColor: arg.resource.extendedProps.background }"></span>
        <div>
          <span>{{ arg.resource.title }}</span>
        </div>
      </div>
    </template>
    <template v-slot:eventContent='arg'>
      <div class="d-flex gap-2 p-1">
        <img :src="arg.event.extendedProps.picture" class="br-50 profile-picture">
        <div>
          <span>{{ arg.event.title }}</span>
        </div>
      </div>
    </template>
  </FullCalendar>
</template>

<script setup>
import { reactive, ref, watch } from 'vue';
import axios from 'axios';
import FullCalendar from '@fullcalendar/vue3';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import allLocales from '@fullcalendar/core/locales-all';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css'; // needs additional webpack config!
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

const startDayTime = ref('09:00');

// Get Employees
async function getEmployees() {
  try {
    const response = await axios.get('/api/employees?isActive=true');
    const employees = await response.data["hydra:member"].map((employee) => ({
          id: employee.id,
          title: employee.user.firstname,
          extendedProps: {
            background: employee.color ?? '#F4F6F9',
          }
        }));
    console.log(employees);
    calendarOptions.resources = employees;
  } catch (error) {
    console.log('getEmployees error:', error);
  }
}

//  Get Events
async function getEvents(day) {
  try {
    const response = await axios.get(`/api/calendar_employees?date[after]=${day}`);
    const events = await response.data["hydra:member"].map((event) => ({
          id: event.id,
          title: 'Event',
          start: event.startAt,
          end: event.endAt,
          resourceId: event.employee.id,
          editable: true,
          backgroundColor: event.employee.color ?? '#F4F6F9',
          borderColor: '#CBD0D8',
          extendedProps: {
            picture: window.location.origin + event.employee.user.profilePicture
          }
        }));
    console.log(events)
    calendarOptions.events = events;
  } catch (error) {
    console.log('getEvents error:', error);
  }
}
// Post Events
async function postEvent(arg) {
  try {
    const response = await axios.post('/api/calendar_employees', {
      employee: `/api/employees/${arg.resource.id}`,
      date: arg.start,
      startAt: arg.start,
      endAt: arg.end,
    })
    console.log(response);
  } catch (error) {
    console.log('postEvent error:', error);
  }
}

const calendarOptions =  reactive({
  themeSystem: 'bootstrap5',
  schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
  locales: allLocales,
  locale: 'fr', // the initial locale
  selectable: true,
  selectMirror: true,
  plugins: [ resourceTimeGridPlugin, interactionPlugin, bootstrap5Plugin ],
  initialView: 'resourceTimeGridDay',
  resources: [],
  events: [],
  // dateClick: handleDateClick,
  select: handleDateSelect,
  slotDuration: '00:15:00',
  slotMinTime: "06:00:00",
  slotMaxTime: "23:00:00",
  businessHours: {
    // days of week. an array of zero-based day of week integers (0=Sunday)
    daysOfWeek: [ 1, 2, 3, 4, 5, 6 ], // Monday - Saturday
    startTime: startDayTime.value, // a start time 
    endTime: '18:00', // an end time (6pm in this example)
  },
  firstDay: 1,
  nowIndicator: true,
  dayMinWidth: 100,
})



const fullCalendar = ref(null);
let calendarApi = ref();
watch(fullCalendar, (newFullCalendar) => {
  calendarApi.value = fullCalendar.value.getApi();
  console.log(calendarApi.value.currentData.currentDate)
  getEvents(calendarApi.value.currentData.currentDate)
});
getEmployees();

// Inutile ( car select mieux )
// function handleDateClick(arg) {
//   console.log(arg)
//   console.log('resourceId:', arg.resource.id)
//   calendarOptions.events.push({title: 'event', date: arg.date, start: arg.dateStr, resourceId: arg.resource.id})
//   //alert('date click! ' + arg.dateStr)
// }

async function handleDateSelect(arg) {
  console.log(arg)
  await postEvent(arg);
  await getEvents(calendarApi.value.currentData.currentDate)
}

function prev() {
  calendarApi.value.prev();
  getEvents(calendarApi.value.currentData.currentDate)
}
function next() {
  calendarApi.value.next();
  getEvents(calendarApi.value.currentData.currentDate)
}
</script>

<style lang="scss">
.fc-scrollgrid-sync-inner {
  display: flex;
}
.color-employee {
  width: .3rem;
  height: 1.5rem;
  border-radius: .5rem;
}
.profile-picture {
  width: 1.4rem;
  height: 1.4rem;
}
</style>