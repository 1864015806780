/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import 'bootstrap';
import './styles/app.scss';
import './styles/color.scss';
// import { createPinia } from 'pinia'

import bsCustomFileInput from 'bs-custom-file-input';

// start the Stimulus application
import './bootstrap';

bsCustomFileInput.init();

// Symfony UX Vue
import { registerVueControllerComponents } from '@symfony/ux-vue';

// Registers Vue.js controller components to allow loading them from Twig
//
// Vue.js controller components are components that are meant to be rendered
// from Twig. These component can then rely on other components that won't be
// called directly from Twig.
//
// By putting only controller components in `vue/controllers`, you ensure that
// internal components won't be automatically included in your JS built file if
// they are not necessary.
registerVueControllerComponents(require.context('./vue/controllers', true, /\.vue$/));


// // Import VueQueryPlugin
// import { VueQueryPlugin } from '@tanstack/vue-query';

// document.addEventListener('vue:before-mount', (event) => {
//     const {
//         componentName, // The Vue component's name
//         component, // The resolved Vue component
//         props, // The props that will be injected to the component
//         app, // The Vue application instance
//     } = event.detail;

//     // Example with Vue Router
//     const router = VueRouter.createRouter({
//         history: VueRouter.createWebHashHistory(),
//         routes: [
//             /* ... */
//         ],
//     });
//     app.use(router);

//     // Example with Pinia (if needed)
//     const pinia = createPinia();
//     app.use(pinia);

//     // Use VueQueryPlugin
//     app.use(VueQueryPlugin);
// });
