<template>


    <div class="m-3">
        <h1 class="mb-1 fs-2"><i class="fa-regular fa-leaf fa-fw"></i> Mes Eucalyptus</h1>
    </div>

    <div class="row m-3 g-3">

        <div class="col-12 col-lg-4 d-flex">
            <div class="card p-4 w-100 border-0 w-shadow br-12">
                <div class="card-body">
                    <p class="mb-0 dg-1">Solde Eucalyptus</p>
                    <p class="mb-0 fs-1 grass-3">{{ eucalyptus.customer.points }}</p>
                    <small class="dg-1">Soit
                        {{ eucalyptus.customer.points / 100 }}
                        €</small>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-4 d-flex">
            <div class="card p-4 w-100 border-0 w-shadow br-12">
                <div class="card-body">
                    <p class="mb-0 dg-1">Total cumulés, depuis le {{ eucalyptus.customer.createdAt }}</p>
                    <p class="mb-0 fs-1 dg-2">{{ eucalyptus.customer.sumPoints }}</p>
                    <small class="dg-1">Soit
                        {{ eucalyptus.customer.sumPoints / 100 }}
                        €</small>
                </div>
            </div>
        </div>

        <div class="col-12 col-lg-6 d-flex">
            <div class="card p-4 w-100 border-0 w-shadow br-12">
                <div class="card-body">
                    <h2 class="fs-3 dg-1">Actions</h2>
                    <div class="d-grid gap-3 d-md-block">
                        <button class="btn btn-primary me-2 mb-md-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDonationRight" aria-controls="offcanvasDonationRight">Donner des Eucalyptus</button>
                        
                        <!-- <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">Planter des arbres</button> -->

                        <button v-if="eucalyptus.customerRoles.includes('ROLE_EMPLOYEE')" class="btn btn-primary me-2 mb-md-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasConvertRight" aria-controls="offcanvasConvertRight">Convertir en Euros</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Tabs -->
        <section class="col-12">
            <hr class="my-4">
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <!-- <li class="nav-item" role="presentation">
                    <button class="btn btn-primary me-2" id="pills-stats-tab" data-bs-toggle="pill" data-bs-target="#pills-stats" type="button" role="tab" aria-controls="pills-stats" aria-selected="true">Statistiques</button>
                </li> -->
                <li class="nav-item" role="presentation">
                    <button 
                        @click="getEucalyptusHistory()"
                        class="btn btn-primary me-2" 
                        id="pills-history-tab" 
                        data-bs-toggle="pill" 
                        data-bs-target="#pills-history" 
                        type="button"
                        role="tab" 
                        aria-controls="pills-history" 
                        aria-selected="true"
                    >
                        Historique
                    </button>
                </li>
            </ul>
            
            <div class="tab-content" id="pills-tabContent">
                <!-- <div class="tab-pane fade" id="pills-stats" role="tabpanel" aria-labelledby="pills-stats-tab" tabindex="0">
                    <h5>Stats</h5>
                </div> -->
                <div class="tab-pane fade" id="pills-history" role="tabpanel" aria-labelledby="pills-history-tab" tabindex="0">
                    <h5>Historique</h5>

                    <div v-for="euca in eucalyptus.customerHistory" :key="euca.id" class="row mb-2">

                        <div class="col-12">
                            <small class="lg-3 ms-auto text-end">{{ euca.createdAt }}</small>
                        </div>
                        <div class="col-9">
                            {{ euca.message }}
                        </div>
                        <div class="col-3 text-end">
                            <span class="badge me-2" :class="[euca.points > 0 ? 'bg-grass-1' : 'bg-grape-1']" >{{ euca.points }}</span>
                        </div>
                    
                    </div>

                </div>
            </div>
        </section>

    </div>


    <!-- Offcanvas - Don d'eucas -->
    <div class="offcanvas offcanvas-end p-4" tabindex="-1" id="offcanvasDonationRight" aria-labelledby="offcanvasDonationRight">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasDonationRightLabel">Donner des Eucalyptus</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            
            <!-- Formulaire -->
            <Transition>
            <div v-if="!eucalyptus.donation.success && !eucalyptus.isLoading">
                <div class="mb-3">
                    <label for="selectEmployee">Sélectionner un⸱e employé⸱e</label>
                    <select 
                        id="selectEmployee" 
                        v-model="eucalyptus.donation.idEmployeeSelected"
                        class="form-select" 
                        aria-label="Sélectionner un⸱e employé⸱e par son prénom"
                    >
                        <option 
                            v-for="employee in eucalyptus.employees" :key="employee.id" :value="employee.id">{{ employee.user.firstname }} {{ employee.user.lastname[0] }}.</option>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="donationAmount">Nombre d'éucalyptus à donner</label>
                    <input 
                        id="donationAmount" 
                        v-model="eucalyptus.donation.amountOfDonation"
                        class="form-control" 
                        type="number"
                        placeholder="100" 
                        aria-label="Montant à donner, en Eucalyptus" 
                        min="0" 
                        :max="eucalyptus.customer.points" 
                        step="1"
                        >
                </div>
                <div v-if="eucalyptus.error" class="mb-3 bg-sunflo-1 br-12 p-4">
                    <ul class="mb-0">
                        <li v-for="errorMessage in eucalyptus.errorMessages" :key="errorMessage">{{ errorMessage }}</li>
                    </ul>
                </div>
                <button 
                    class="btn btn-primary"
                    @click="sendEucalyptus()"
                >
                    Donner <span v-if="eucalyptus.donation.amountOfDonation">{{ eucalyptus.donation.amountOfDonation }} eucalyptus</span>
                </button>
            </div>
            </Transition>

            <!-- Loader -->
            <Transition>
            <div v-if="eucalyptus.isLoading">
                <p><i class="fa-regular fa-spinner-third fa-spin me-1"></i> Chargement</p>
            </div>
            </Transition>

            <!-- Success banière -->
            <Transition>
            <div v-if="eucalyptus.donation.success" class="my-3 bg-grass-1 br-12 p-4">
                <p class="mb-0 dg-3"><i class="fa-regular fa-thumbs-up fa-fw"></i> Eucalyptus transférés. Merci pour votre générosité.</p>
            </div>
            </Transition>
        </div>
    </div>

    <!-- Offcanvas - Conversion d'eucas -->
    <div class="offcanvas offcanvas-end p-4" tabindex="-1" id="offcanvasConvertRight" aria-labelledby="offcanvasConvertRight">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasConvertRightLabel">Convertir mes Eucalyptus</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">

            <p>Prochainement sur vos écrans...</p>
        
        </div>
    </div>

    
</template>

<script setup>

    import { reactive, onMounted} from "vue";
    import axios from 'axios';
    
    const props = defineProps(['customerId'])
    //console.log(props.customerId);

    const eucalyptus = reactive({
        customer: {},
        customerRoles: [],
        customerHistory: {},

        employees: [],
        
        isLoading: false,

        donation: {
            idEmployeeSelected: 0,
            amountOfDonation : 0,
            success: false
        },

        error: false,
        errorMessages: []
    })

    /*
        Get le Customer 
    */
    onMounted(() => {
        getCustomer();
        getEucalyptusHistory();
    })
    /*
        Get Employées 
    */
    onMounted(() => {
        axios.get('/api/employees?isActive=true')
            .then(response => {
                //console.log('Get les employees', response.data['hydra:member']);
                eucalyptus.employees = response.data['hydra:member'];
            })
    })


    function sendEucalyptus() {

        // init
        eucalyptus.error = false;
        eucalyptus.errorMessages = [];

        // Erreur Employée
        if (eucalyptus.donation.idEmployeeSelected === 0 || eucalyptus.donation.idEmployeeSelected === eucalyptus.customer.id) {
            eucalyptus.error = true;
            eucalyptus.errorMessages.push("Erreur liée à l'employé⸱e sélectionné⸱e");
        }
        // Erreur montant
        if (eucalyptus.donation.amountOfDonation <= 0 || eucalyptus.donation.amountOfDonation > eucalyptus.customer.points) {
            eucalyptus.error = true;
            eucalyptus.errorMessages.push("Montant d'Eucalyptus invalide");
        }
        
        // POST
        if (!eucalyptus.error) {

            // Loader
            eucalyptus.isLoading = true;
            
            // Post du don
            axios.post('/api/eucalyptus_donations', {
                "customer": '/api/users/' + eucalyptus.customer.id,
                "employee": '/api/employees/' + eucalyptus.donation.idEmployeeSelected,
                "points": eucalyptus.donation.amountOfDonation,
            })
                .then(response => {
                    //console.log('Post de la donation', response);

                    // Update des infos du customer
                    getCustomer();
                    // Show le message de success
                    showDonationSuccess();
                    
                    // Réinititailisation des champs
                    eucalyptus.donation.idEmployeeSelected = 0;
                    eucalyptus.donation.amountOfDonation = 0;
                    eucalyptus.isLoading = false;

                })

        }
    }


    function getCustomer() {
        axios.get('/api/users/' + props.customerId)
            .then(response => {
                //console.log('Get le customer', response.data);
                eucalyptus.customer = response.data;
                eucalyptus.customerRoles = response.data['roles'];
            })
    }

    function getEucalyptusHistory() {
        axios.get('/api/eucalyptuses?user=' + props.customerId + '&order[createdAt]=DESC')
            .then(response => {
                //console.log('Get le customer', response.data);
                eucalyptus.customerHistory = response.data['hydra:member'];
                console.log(eucalyptus.customerHistory);
            })
    }

    function showDonationSuccess() {
        eucalyptus.donation.success = true;
        // Masquage du message de success
        setTimeout(() => {
            eucalyptus.donation.success = false;
        }, 4000);
    }

    
</script>


<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>