<template>

    <section class="row py-5">

        <div class="col-12 col-lg-10 mx-auto fade-in"> 
            
            <div class="text-center">
                <i class="fa-light fa-star fa-fw green-1 fa-4x mb-3"></i>
                <h2 class="">Avis aléatoire</h2>
                <p class="dg-2 w-75 mx-auto">Voici quelques avis laissés par nos client⸱e⸱s depuis leur espace fidélité. La sélection est complètement aléatoire ! Amusez-vous à en faire défiler quelques-uns 😉</p>
            </div>
            
            <div class="card p-4">
                <div class="card-body">
                    <article v-if="!showLoader" class="text-center fade-in">
                        
                        <div class="evaluation mb-3">
                            <div class="note">
                                <span class="fs-1 fw-bold sunflo-3">{{ rating.noteTotale }}</span><span class="fs-4 dg-1">/5</span>
                            </div>
                            <div class="stars">
                                <span v-if="rating.noteTotale > 4.5">
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                </span>
                                <span v-else-if="rating.noteTotale > 4 && rating.noteTotale <= 4.5">
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star-half sunflo-3"></i>
                                </span>
                                <span v-else-if="rating.noteTotale > 3.5 && rating.noteTotale <= 4">
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                </span>
                                <span v-else-if="rating.noteTotale > 3 && rating.noteTotale <= 3.5">
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star-half sunflo-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                </span>
                                <span v-else-if="rating.noteTotale > 2.5 && rating.noteTotale <= 3">
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                </span>
                                <span v-else-if="rating.noteTotale > 2 && rating.noteTotale <= 2.5">
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star-half sunflo-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                </span>
                                <span v-else-if="rating.noteTotale > 1.5 && rating.noteTotale <= 2">
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                </span>
                                <span v-else-if="rating.noteTotale > 1 && rating.noteTotale <= 1.5">
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star-half sunflo-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                </span>
                                <span v-else-if="rating.noteTotale > 0.5 && rating.noteTotale <= 1">
                                    <i class="fa-solid fa-star sunflo-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                </span>
                                <span v-else-if="rating.noteTotale > 0 && rating.noteTotale <= 0.5">
                                    <i class="fa-solid fa-star-half sunflo-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                </span>
                                <span v-else-if="rating.noteTotale == 0 ">
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                    <i class="fa-solid fa-star lg-3"></i>
                                </span>
                            </div>
                        </div>
                        

                        <p class="fs-4 mb-1">
                        {{ rating.service }}
                        </p>

                        <p class="dg-2">
                            <i class="fa-regular fa-quote-left"></i>
                            {{ rating.comGlobal }}
                            <i class="fa-regular fa-quote-right"></i>
                        </p>

                        <p class="dg-2 fs-09">
                            Avis posté le 
                            
                            <span v-if="rating.createdAt">{{ rating.createdAt }}</span> 
                            <span v-else>"date inconnue"</span>  
                            
                            par 

                            <span v-if="rating.customer">{{ rating.customer.firstname }}</span> 
                            <span v-else>"une personne mystérieuse"</span> 
                            
                            <!-- avec 

                            <span v-if="rating.employee != null && rating.employee.isActive" class="blue-2">{{ rating.employee.user.firstname }}</span>
                            <span v-else class="blue-2">"Une personne géniale !"</span> -->
                        </p>
                    </article>
                    <div v-else class="col-12 col-lg-6 col-xl-4 mx-auto p-4 text-center">
                        <p class="my-5 dg-1"><i class="fa-regular fa-spinner-third fa-spin me-1"></i>Chargement d'un avis</p>
                    </div>
                    <div class="d-flex justify-content-center">
                        <button class="btn btn-outline-green" @click="getNewRating">Voir un autre avis</button>
                    </div>
                </div>
            </div>
        </div>
        
    </section>

</template>

<script setup>

    import { ref, reactive, onMounted } from "vue";
    import axios from 'axios'

    const showLoader = ref(true)
    const rating = ref('')

    const ratings = reactive({
        'list': [],
        'count': 0
    })
    
    const getNewRating = () => {
        showLoader.value = true

        const random = Math.floor(Math.random() * ratings.count);
        rating.value = ratings.list[random]

        console.log('Avis : ' + random + '/' + ratings.count)

        showLoader.value = false
    }

    onMounted(() => {
        axios.get('/api/customer_ratings?exists[comGlobal]=true&order[id]=desc')
            .then(response => {
                showLoader.value = true
                ratings.list = response.data['hydra:member']
                ratings.count = response.data['hydra:member'].length
                getNewRating();
            });
    })

</script>

<style>
    .fade-in {
        animation: fadeIn 1s;
    }

    @keyframes fadeIn {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }
</style>
