<template>

    <div class="row">
        <div class="col-12 col-lg-2 nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <button 
                v-for="room in state.houseworksByRoom" 
                :key="`btn_${room.id}`"
                class="nav-link mb-1 d-flex justify-content-between align-items-center" 
                :id="`v-pills-${room.id}-tab`" 
                data-bs-toggle="pill" 
                :data-bs-target="`#v-pills-${room.id}`" 
                type="button" 
                role="tab" 
                :aria-controls="`v-pills-${room.id}`" 
                aria-selected="true"
            >
                {{ room.name }}
                <span class="badge bg-green-1" v-if="room.houseworks.length > 0">{{ room.houseworks.length }}</span>
            </button>
        </div>
        <div class="col-12 col-lg-7 tab-content" id="v-pills-tabContent">
            <div 
                v-for="room in state.houseworksByRoom"
                :key="`pills_${room.id}`"
                class="tab-pane fade" 
                :id="`v-pills-${room.id}`" 
                role="tabpanel" 
                :aria-labelledby="`v-pills-${room.id}-tab`" 
                tabindex="0"
            >
                <div class="row g-2" v-if="room.houseworks.length > 0">
                    <!-- V-FOR Houseworks -->
                    <Housework v-for="housework in room.houseworks" :key="housework.id" :houseworkId="housework.id"></Housework>
                        
                </div>
            </div>
            
        </div>
        <div class="col-12 col-lg-3">
            <div class="card p-3 br-12 border-lg-3 bg-transparent">
                <div class="card-body">
                    <h6>10 dernières tâches effectuées</h6>
                    <template v-if="state.lastTenHouseworksDone.length > 0">
                        <p class="mb-0 dg-1" v-for="lastTen in state.lastTenHouseworksDone" :key="lastTen.id">{{ lastTen.housework.name }}<small class="fst-italic"> - {{ lastTen.housework.room.name }}</small></p>
                    </template>
                    <template v-else>
                        <p class="mb-0 dg-1">Il n'y a pas de tâches à afficher !</p>
                    </template>
                </div>
            </div>
        </div>
    </div>

    <!-- V-FOR Rooms -->
    <!-- <div class="row g-3 mb-3" v-for="room in state.houseworksByRoom" :key="room.name">
        
        <template v-if="room.houseworks.length > 0">
            <div class="col-12">
                <h3 class="mb-0 fs-4 dg-1 fw-bold">{{ room.name }}</h3>
            </div>

            <Housework v-for="housework in room.houseworks" :key="housework.id" :houseworkId="housework.id"></Housework>
                
        </template>
        
    </div> -->
            
</template>

<script setup>

    import { reactive } from "vue";
    import axios from 'axios'

    import Housework from '/assets/vue/components/Housework.vue'

    /**
     * Initialisation du composant
     */
    const state = reactive({
        houseworksByRoom: [],
        lastTenHouseworksDone: []
    })

    axios.get('/api/houseworks-by-rooms')
        .then(response => {
            // console.log(response);
            state.houseworksByRoom = response.data['hydra:member'];
            //console.log(state.houseworksByRoom);
        });

    axios.get('/api/last-ten-houseworks-dones?order[createdAt]=desc')
        .then(response => {
            // console.log(response);
            state.lastTenHouseworksDone = response.data['hydra:member'];
            //console.log(state.lastTenHouseworksDone);
        });

    setInterval(() => {
        axios.get('/api/last-ten-houseworks-dones?order[createdAt]=desc')
        .then(response => {
            // console.log(response);
            state.lastTenHouseworksDone = response.data['hydra:member'];
            //console.log(state.lastFiveHouseworksDone);
        });
    }, 10000);

</script>