<template>

    <form class="row mb-3" action="" @submit.prevent="validateForm()">
        <!-- Sélecteur de jour -->
        <div class="col-12 col-lg-6 col-xxl-4">
            <div class="card br-12 w-100 p-4 mb-3">
                <div class="card-body">      
                    <div class="mb-3">
                        <label for="date" class="form-label">Date</label>
                        <input 
                            id="date"
                            type="date" 
                            class="form-control" 
                            v-model="dayCashRegisterFund.day"
                            >
                    </div>
                    <p class="mb-0 grape-2" v-if="dayCashRegisterFund.dayError">
                        {{ dayCashRegisterFund.dayError }}
                    </p>
                </div>
            </div>
        </div>

        <!-- Formulaire de saisie -->
        <div class="col-12 col-lg-6 col-xxl-4">
            <div class="card br-12 w-100 p-4">
                <div class="card-body">

                    <div class="mb-3">
                        <label for="cards" class="form-label"><i class="fa-regular fa-credit-card-front"></i> CB</label>
                        <input 
                            id="cards" 
                            type="number" 
                            min="0" 
                            step="0.01" 
                            class="form-control wheel"
                            v-model="dayCashRegisterFund.cards"
                            >
                    </div>

                    <div class="mb-3">
                        <label for="cash" class="form-label"><i class="fa-regular fa-money-bill-1"></i> Espèces</label>
                        <input 
                            id="cash" 
                            type="number" 
                            min="0" 
                            step="0.01" 
                            class="form-control wheel"
                            v-model="dayCashRegisterFund.cash"
                            >
                    </div>

                    <div class="mb-3">
                        <label for="bankCheck" class="form-label"><i class="fa-regular fa-money-check"></i> Chèques <small class="grape-2 fst-italic">normalement refusés</small></label>
                        <input 
                            id="bankCheck" 
                            type="number" 
                            min="0" 
                            step="0.01" 
                            class="form-control wheel" 
                            placeholder="0"
                            v-model="dayCashRegisterFund.bankCheck"
                            >
                    </div>

                    <div class="mb-3" v-if="dayCashRegisterFund.errorsMessages.length > 0">
                        <p class="grape-2 mb-0 fs-5 fw-bold"><i class="fa-regular fa-dinosaur"></i> Erreur {{ dayCashRegisterFund.errorCode }}</p>
                        <p class="mb-1 grape-2" v-for="error in dayCashRegisterFund.errorsMessages">
                            {{ error }}
                        </p>
                        <small>Vous n'avez pas compris ce message pourtant limpide ? Contactez nico...</small>
                    </div>

                    <!-- Validation -->
                    <div class="d-flex justify-content-end mt-3">
                        <input 
                            v-if="!dayCashRegisterFund.load"
                            type="submit" 
                            @keypress.enter="validateForm()" 
                            value="Valider 🖖" 
                            class="btn btn-primary"/>
                        <button v-else class="btn btn-secondary"><i class="fa-regular fa-spinner-third fa-fw fa-spin"></i> Sauvegarde en cours</button>
                    </div>

                </div>
            </div>
        </div>

    </form>
        

    <!-- Table de récap -->
    <h2>Table</h2>
    <table class="table" v-if="!tableList.load">
        <thead>
            <tr>
                <th scope="col"><i class="fa-regular fa-calendar-days"></i> Journée</th>
                <th scope="col"><i class="fa-regular fa-credit-card-front"></i> Cartes</th>
                <th scope="col"><i class="fa-regular fa-money-bill-1"></i> Espèces</th>
                <th scope="col"><i class="fa-regular fa-money-check"></i> Chèques</th>
                <th scope="col"><i class="fa-regular fa-calendar-days"></i> Validé le</th>
            </tr>
        </thead>
        <tbody>
            <!-- For Jour du mois -->
            <tr v-for="line in tableList.list" :key="line.id">
                <th scope="row">{{ line.day }}</th>
                <td :class="{'lg-3': line.cards == 0}">{{ line.cards }} €</td>
                <td :class="{'lg-3': line.cash == 0}">{{ line.cash }} €</td>
                <td :class="{'lg-3': line.bankCheck == 0}">{{ line.bankCheck }} €</td>
                <td>{{ line.validatedAt }}</td>
            </tr>
        </tbody>
        
        <!-- If saisi -->
        
    </table>

</template>

<script setup>

    import { reactive, onMounted } from "vue";
    import axios from 'axios'

    const dayCashRegisterFund = reactive({
        day: '',
        dayError: '',
        cash: 0,
        cards: 0,
        bankCheck: 0,
        load: false,
        errorsMessages: [],
        errorCode: ''
    })

    const tableList = reactive({
        list: [],
        load: false
    });

    // Liste mois en cours
    onMounted(() => {
        loadList();
    })


    function loadList() {
        // loading
        tableList.load = true;

        axios.get('/api/funds?order[day]=desc')
            .then(response => {
                //console.log(response);
                tableList.list = response.data['hydra:member'];
                tableList.load = false;
            })
    }



    function validateForm()
    {
        //console.log('Début de validate : ', dayCashRegisterFund)

        // Chargement 
        dayCashRegisterFund.load = true;
        dayCashRegisterFund.errorsMessages = [];
        dayCashRegisterFund.errorCode = '';

        // Vérifications
        if(dayCashRegisterFund.day == '') {
            dayCashRegisterFund.errors.push('Jour non séléctionné')
        }

        axios.post('/api/funds', { 
            day: dayCashRegisterFund.day,
            cash: dayCashRegisterFund.cash * 100,
            cards: dayCashRegisterFund.cards * 100,
            bankCheck: dayCashRegisterFund.bankCheck * 100,
        })
            .then(response => {
                //console.log('Then : ', dayCashRegisterFund)
                //console.log(response);

                console.log('✅ Journée postée')

                // Init 
                initDayCashRegisterFund();

                //console.log('After init : ', dayCashRegisterFund)
            })
            .catch(error => {
                if (error.response) {
                    //console.log(error.response);

                    //console.log('Error : ', dayCashRegisterFund)

                    // Texte de l'erreur
                    let errorText = error.response.data['hydra:description']

                    // Code Erreur
                    dayCashRegisterFund.errorCode = error.response.status;

                    // Si texte contient...
                    if (errorText.includes('Duplicate entry')) {
                        dayCashRegisterFund.errorsMessages.push('Le jour sélectionné a déjà été saisi')
                    } else {
                        dayCashRegisterFund.errorsMessages.push(errorText);
                    }
                    
                    // Fin du loading
                    dayCashRegisterFund.load = false;
                }
            });
    }

    function initDayCashRegisterFund () {
        dayCashRegisterFund.day = '';
        dayCashRegisterFund.dayError = '';
        dayCashRegisterFund.cards = 0;
        dayCashRegisterFund.cash = 0;
        dayCashRegisterFund.bankCheck = 0;
        dayCashRegisterFund.load = false;
        dayCashRegisterFund.errorsMessages = [];
        dayCashRegisterFund.errorCode = '';

        // reload la liste
        loadList();
    };
   
</script>
