<template>

    <div class="row">
        <div class="col-12 col-lg-11 mx-auto">
            <div class="accordion" id="accordionExample">
                <div class="accordion-item" v-for="category of state.categories">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#${category.id}`" aria-expanded="false" :aria-controls="category.id">
                            {{ category.name }}
                        </button>
                    </h2>
                    <div :id="category.id" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                        <div class="accordion-body">
                            <draggable
                                v-model=category.services
                                tag="ul"
                                class="list-group"
                                :animation="300"
                                item-key="id"
                                @end="updateItemOrder(category.services, $event)"
                            >
                                <template #item="{ element: service }">
                                    <li
                                        class="list-group-item d-flex justify-content-between align-items-center"
                                        :rank="service.rank"
                                        :name="service.name"
                                        :key="service.id"
                                    >   
                                        <div class="flex-fill row">
                                            <span class="col-2 col-xl-1 fw-bold d-flex align-items-center gap-2">
                                                <i class="fa-regular fa-hashtag"></i>
                                                {{ service.rank }}
                                            </span>
                                            <span class="col-auto">
                                                {{ service.name }}
                                            </span>
                                        </div>
                                        <div class="d-flex gap-3">
                                            <span class="text-muted">
                                                {{ service.price / 100 }}€
                                            </span>
                                            <span>
                                                <i class="fa-solid fa-grip-dots-vertical"></i>
                                            </span>
                                        </div>
                                    </li>
                                </template>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>  
        </div>
    </div>

</template>

<script setup>

    import { reactive } from "vue";
    import axios from 'axios';
    import draggable from 'vuedraggable';


    /**
     * Initialisation du composant
     */
    const state = reactive({
        categories: [],
        services: []
    })

    axios.get('/api/categories?order[rank]=asc')
        .then(response => {
            // console.log(response);
            state.categories = response.data['hydra:member'];
        });


    const axiosPatchConfig = { 
        headers: { 'Content-Type': 'application/merge-patch+json' }
    }

    function updateItemOrder(list, evt) {
        // console.log('list 1', list)
        // console.log(evt);

        const newIndex = evt.newIndex;
        const oldIndex = evt.oldIndex;
        const serviceMoved = list[oldIndex];

        if (newIndex === oldIndex) return

        // update all items rank localy
        if (newIndex > oldIndex) {
            // decrement rank of all items oldIndex and newIndex
            for (let i = oldIndex; i < newIndex; i++) {
                const newRank = list[i].rank - 1;
                list[i].rank = newRank;
                axios.patch(`/api/services/${list[i].id}`, {
                    "rank": newRank,
                }, axiosPatchConfig);
            }
            
            // update rank of moved item
            const newRank = newIndex + 1;
            list[newIndex].rank = newRank;
            axios.patch(`/api/services/${list[newIndex].id}`, {
                "rank": newRank,
            }, axiosPatchConfig);
        } else {
            // increment rank of all items oldIndex and newIndex
            for (let i = newIndex; i <= oldIndex; i++) {
                const newRank = list[i].rank + 1;
                list[i].rank = newRank;
                axios.patch(`/api/services/${list[i].id}`, {
                    "rank": newRank,
                }, axiosPatchConfig);
            }

            // update rank of moved item
            const newRank = newIndex + 1;
            list[newIndex].rank = newRank;
            axios.patch(`/api/services/${list[newIndex].id}`, {
                "rank": newRank,
            }, axiosPatchConfig);
        }
    }
</script>