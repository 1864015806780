<template>
    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title
                    ">Sales</h4>
                </div>
                <div class="card-body">
                    <div id="line-chart" class="apex-charts" dir="ltr"></div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title
                    ">Revenue</h4>
                </div>
                <div class="card-body">
                    <div id="bar-chart" class="apex-charts" dir="ltr"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

</script>