<template>

    <div class="m-3">
        <h1 class="mb-1 fs-2"><i class="fa-regular fa-flask fa-fw"></i> Le labo</h1>
    </div>


    <div class="row my-3 g-3">
        
        <!-- Actions -->
        <div class="col-12 col-lg-3 d-flex flex-column">
            <button class="btn btn-outline-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapsePublier" aria-expanded="false" aria-controls="collapsePublier">Publier</button>
        </div>

        <div class="col-12 col-lg-6">

            <!-- PUBLIER -->
            <div id="collapsePublier" class="mb-3 collapse d-flex flex-column align-items-end">
                <textarea id="newPostTextarea" type="text" class="form-control mb-2" v-model="state.newPost" autofocus @keypress.enter="saveNewPost"></textarea>
                <button class="btn btn-primary" @click="saveNewPost">Valider</button>
            </div>

            <!-- POST -->
            <TransitionGroup name="postsList">
                <div v-for="post in state.postsList" :key="post.id" class="card card-body p-4 br-12 mb-2 mb-lg-3">

                    <!-- Avatar et Nom -->
                    <div class="mb-1 d-flex align-items-top justify-content-between">
                        <span class="d-flex align-items-center">
                            <img :src="post.employee.user.profilePicture" :alt="post.employee.user.firstname" class="mini-avatar me-2">
                            <span>
                                <p class="mb-0 fw-bold employee-name">{{ post.employee.user.firstname }}</p>
                                <small class="dg-1 mt-0">{{ post.employee.jobTitle }}</small>
                            </span>
                        </span>
                        <span v-if="post.employee.id === props.employeeId" @click="removePost(post.id)">
                            <i class="fa-regular fa-circle-xmark fa-fw grape-2 cursor"></i>
                        </span>
                        
                    </div>
            
                    <!-- Post -->
                    <div class="mb-0">
                        <p class="mb-0">{{ post.post }}</p>
                    </div>
            
                    <!-- Date -->
                    <div class="mb-0">
                        <small class="mb-0 fst-italic dg-1">{{ showDateInGoodFormat(post.createdAt) }}</small>
                    </div>
            
                    <!-- Reactions -->
                    <!-- <div class="my-1 d-flex">
                        <span class="bg-lg-2 br-20 px-2 py-1">
                            <i class="fa-regular fa-circle-xmark fa-fw grape-2"></i>
                            <i class="fa-regular fa-circle-xmark fa-fw grape-2"></i>
                            <i class="fa-regular fa-circle-xmark fa-fw grape-2"></i>
                            <i class="fa-regular fa-circle-xmark fa-fw grape-2"></i>
                        </span>
                    </div> -->
            
                    <!-- <hr>
            
                    <div class="my-1">
                        <input type="text" class="form-control">
                        <button class="btn btn-primary">Valider</button>
                    </div> -->
                </div>
            </TransitionGroup>
        </div>

        <!-- Col Réservé - Pub -->
        <div class="col-3 d-none d-lg-block d-flex flex-column">
            <h5>Pubs</h5>
            <img src="/img/pub-retro/petit-ecolier.jpg" alt="" class="img-fluid">
        </div>

    </div>

</template>

<script setup>

    import { reactive, watch } from "vue";
    import axios from 'axios';

    const props = defineProps(['employeeId'])

    const state = reactive({
        newPost: '',
        postsList: []
    })

    
    /*
        LOADING ALL POSTS
    */
    axios.get('/api/labo_posts/')
        .then(response => {
            console.log('init')
            state.postsList = response.data['hydra:member']
        }).catch(err => {
            console.log(err);
        })

    watch(state.postsList, (newValue) => {
        axios.get('/api/labo_posts/')
            .then(response => {
                console.log('watch')
                state.postsList = response.data['hydra:member']
            }).catch(err => {
                console.log(err);
            })
    })

    
    /*
        PUBLISH A NEW POST
    */
    const saveNewPost = () => {
        axios.post('/api/labo_posts', {
            employee: '/api/employees/' + props.employeeId,
            post: state.newPost
        }).then(response => {
            console.log('New post saved !')

            // Masquer le formulaire et vider l'input 🚨🚨🚨 Vider ne fonctionne pas !
            const textarea = document.querySelector('#newPostTextarea');
            textarea.value = '';
            const collapsePublier = document.querySelector('#collapsePublier');
            collapsePublier.classList.remove('show');

            // Récupération du Post complet ( avec infos employee )
            getTheNewPost(response.data.id)

        }).catch(err => {
            console.log(err);
        })
    }
    const getTheNewPost = (id) => {
        axios.get('/api/labo_posts/' + id)
        .then(response => {
            console.log('Get the new post')
            state.postsList.unshift(response.data)
        }).catch(err => {
            console.log(err);
        })
    }

    /*
        REMOVE A POST
    */
    const removePost = (id) => {
        axios.delete('/api/labo_posts/' + id)
        .then(response => {
            console.log('✅ Post removed')
            state.postsList.splice(
                state.postsList.findIndex((post) => {
                    post.id = id
                }), 1);
        }).catch(err => {
            console.log(err);
        })
    }




    /*
        METHODS DE MISE EN FORME
    */
    // ❤️❤️❤️ Mise en forme de la date de validation de la tâche
    function showDateInGoodFormat(date) {

        let validationDate = new Date(date);
        let today = new Date();

        let diff = dateDiff(validationDate, today);

        //console.log(diff);

        // Aujourd hui
        if (diff.day < 1) {

            if (diff.hour < 1 && diff.min < 1) {
                return "À l'instant !" 
            } else if (diff.hour < 1) {
                if (diff.min == 1) {
                    return "Il y a " + diff.min + " minute";
                } else {
                    return "Il y a " + diff.min + " minutes";
                }
            } else {

                let message = 'Il y a ';

                if (diff.hour == 1) {
                    message += diff.hour + " heure et ";
                } else {
                    message += diff.hour + " heures et ";
                }

                if (diff.min == 1) {
                    message += diff.min + " minute";
                } else {
                    message += diff.min + " minutes";
                }

                return message;
            }
            
        // Hier
        } else if (diff.day == 1) {
            return `Hier, à ${validationDate.getHours()}h${validationDate.getMinutes()}min`
            
        // Il y a x jours ( moins d'un mois )
        } else if (diff.day > 1) {
            return 'Il y a ' + diff.day + ' jours'

        // Plus d'un mois
        } else {
            return "Il y a plus d'un mois !"
        }
    }

    // ❤️❤️❤️ Méthode piqué quelque part paemttant de calculer la différence netre 2 date en JS et retorunant un objet détaillé avec Minutes, Heures...
    function dateDiff(date1, date2){
        let diff = {}                           // Initialisation du retour
        let tmp = date2 - date1;

        tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
        diff.sec = tmp % 60;                    // Extraction du nombre de secondes

        tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
        diff.min = tmp % 60;                    // Extraction du nombre de minutes

        tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
        diff.hour = tmp % 24;                   // Extraction du nombre d'heures

        tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
        diff.day = tmp;

        return diff;
    }



</script>


<style>
.postsList-enter-from,
.postsList-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

.postsList-move,
.postsList-enter-active,
.postsList-leave-active {
  transition: all 0.5s ease;
}

.postsList-leave-active {
  position: absolute;
}
</style>