<template>

    <div class="row g-3">

        <div class="col-12">
            <div class="card br-10 p-3">
                <div class="card-body">
                    <div class="d-flex justify-content-between">
                        <h2 class="fs-4 mb-0">{{ passage.serviceName }}</h2>
                        <span class="dg-2">{{ passage.createdAt }}</span>
                    </div>

                    <div class="d-flex justify-content-between">
                        <p class="mb-0">Avec {{ passage.employee.firstname }}</p>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="col-12" v-if="(!rating.isSend && !passage.isRated)">
            <div class="card br-10 p-3 bg-blue-1">
                <div class="card-body">
                    <p class="mb-1">Merci de faire preuve de bienveillance et d'honneteté en remplissant ce formulaire.</p>
                    <p class="mb-0">Il y a des personnes, Humaines, qui vont lire vos réponses et y être particulièrement sensibles.</p>
                </div>
            </div>
        </div>

    
        <!-- Accueil -->
        <div class="col-12" v-if="(!rating.isSend && !passage.isRated)">
            <div class="card br-10 p-3">
                <div class="card-body">

                    <h4 class="fs-5">
                        Accueil 
                        <span class="grass-2" v-if="(rating.accueil.note != 0)"><i class="fa-solid fa-check fa-fw"></i></span>
                        <span class="fs-07 grape-2" v-else>obligatoire</span>
                    </h4>

                    <div class="my-3">
                        <span 
                            v-for="n in 5"
                            @click="defineNoteAccueil(n)"
                        >
                            <i :class="{
                                'sunflo-2': rating.accueil.note >= n,
                                'lg-3': rating.accueil.note < n, 
                            }" class="fa-solid fa-star fa-fw fa-2x "></i>
                        </span>
                        <span class="fs-2 sunflo-3 ms-1">{{ rating.accueil.note }}</span>
                        <span class="dg-2"> / 5</span>
                    </div>

                    <div class="form-floating">
                        <textarea 
                            id="accueil" 
                            name="accueil" 
                            v-model="rating.accueil.com"
                            class="form-control"
                            :class="{
                                'border-grape-2': rating.accueil.note != 0 && rating.accueil.note != 5 && rating.accueil.com.length < 10
                            }"
                            style="height: 100px"></textarea>
                        <label for="accueil">
                            Commentaire 
                            <small class="aqua-2" v-if="(rating.accueil.note == 0 || rating.accueil.note == 5)">optionnel</small>
                            <small class="grape-2" v-else-if="(rating.accueil.note == 0 || ( rating.accueil.note != 5 && rating.accueil.com.length < 10) )">obligatoire - min 10 caractères</small>
                            <small class="grass-2" v-else><i class="fa-solid fa-check fa-fw"></i></small>
                        </label>
                        <small class="grape-1 mt-1" v-if="(rating.accueil.note != 0 && rating.accueil.note != 5 && rating.accueil.com.length < 10)">Une note inférieur à 5 est possible, mais elle doit être justifiée afin que nous puissions nous améliorer</small>
                        <small class="grass-3 mt-1" v-else-if="(rating.accueil.note != 0 && rating.accueil.note != 5 && rating.accueil.com.length >= 10)">Merci. Nous allons prendre en considération votre remarque.</small>
                    </div>

                </div>
            </div>
        </div>

        <!-- Disponibilité et Écoute -->
        <div class="col-12" v-if="(!rating.isSend && !passage.isRated)">
            <div class="card br-10 p-3">
                <div class="card-body">

                    <h4 class="fs-5">
                        Disponibilité et Écoute 
                        <span class="grass-2" v-if="(rating.ecoute.note != 0)"><i class="fa-solid fa-check"></i></span>
                        <span class="fs-07 grape-2" v-else>obligatoire</span>
                    </h4>

                    <div class="my-3">
                        <span 
                            v-for="n in 5"
                            @click="defineNoteEcoute(n)"
                        >
                            <i :class="{
                                'sunflo-2': rating.ecoute.note >= n,
                                'lg-3': rating.ecoute.note < n, 
                            }" class="fa-solid fa-star fa-fw fa-2x "></i>
                        </span>
                        <span class="fs-2 sunflo-3 ms-1">{{ rating.ecoute.note }}</span>
                        <span class="dg-2"> / 5</span>
                    </div>

                    <div class="form-floating">
                        <textarea 
                            id="ecoute" 
                            name="ecoute" 
                            v-model="rating.ecoute.com"
                            class="form-control"
                            :class="{
                                'border-grape-2': rating.ecoute.note != 0 && rating.ecoute.note != 5 && rating.ecoute.com.length < 10
                            }" 
                            style="height: 100px"></textarea>
                        <label for="ecoute">
                            Commentaire 
                            <small class="aqua-2" v-if="(rating.ecoute.note == 5)">optionnel</small>
                            <small class="grape-2" v-else-if="(rating.ecoute.note != 5 && rating.ecoute.com.length < 10)">obligatoire - min 10 caractères</small>
                            <small class="grass-2" v-else><i class="fa-solid fa-check fa-fw"></i></small>
                        </label>
                        <small class="grape-1 mt-1" v-if="(rating.ecoute.note != 0 && rating.ecoute.note != 5 && rating.ecoute.com.length < 10)">Une note inférieur à 5 est possible, mais elle doit être justifiée afin que nous puissions nous améliorer</small>
                        <small class="grass-3 mt-1" v-else-if="(rating.ecoute.note != 0 && rating.ecoute.note != 5 && rating.ecoute.com.length >= 10)">Merci. Nous allons prendre en considération votre remarque.</small>
                    </div>

                </div>
            </div>
        </div>

        <!-- Qualité -->
        <div class="col-12" v-if="(!rating.isSend && !passage.isRated)">
            <div class="card br-10 p-3">
                <div class="card-body">

                    <h4 class="fs-5">
                        Qualité
                        <span class="grass-2" v-if="(rating.qualite.note != 0)"><i class="fa-solid fa-check"></i></span>
                        <span class="fs-07 grape-2" v-else>obligatoire</span>
                    </h4>

                    <div class="my-3">
                        <span 
                            v-for="n in 5"
                            @click="defineNoteQualite(n)"
                        >
                            <i :class="{
                                'sunflo-2': rating.qualite.note >= n,
                                'lg-3': rating.qualite.note < n, 
                            }" class="fa-solid fa-star fa-fw fa-2x "></i>
                        </span>
                        <span class="fs-2 sunflo-3 ms-1">{{ rating.qualite.note }}</span>
                        <span class="dg-2"> / 5</span>
                    </div>

                    <div class="form-floating">
                        <textarea 
                            id="qualite" 
                            name="qualite" 
                            v-model="rating.qualite.com"
                            class="form-control" 
                            :class="{
                                'border-grape-2': rating.qualite.note != 0 && rating.qualite.note != 5 && rating.qualite.com.length < 10
                            }" 
                            style="height: 100px"></textarea>
                        <label for="qualite">
                            Commentaire 
                            <small class="aqua-2" v-if="(rating.qualite.note == 5)">optionnel</small>
                            <small class="grape-2" v-else-if="(rating.qualite.note != 5 && rating.qualite.com.length < 10)">obligatoire - min 10 caractères</small>
                            <small class="grass-2" v-else><i class="fa-solid fa-check fa-fw"></i></small>
                        </label>
                        <small class="grape-1 mt-1" v-if="(rating.qualite.note != 0 && rating.qualite.note != 5 && rating.qualite.com.length < 10)">Une note inférieur à 5 est possible, mais elle doit être justifiée afin que nous puissions nous améliorer</small>
                        <small class="grass-3 mt-1" v-else-if="(rating.qualite.note != 0 && rating.qualite.note != 5 && rating.qualite.com.length >= 10)">Merci. Nous allons prendre en considération votre remarque.</small>
                    </div>

                </div>
            </div>
        </div>

        <!-- Propreté / Hygiène -->
        <div class="col-12" v-if="(!rating.isSend && !passage.isRated)">
            <div class="card br-10 p-3">
                <div class="card-body">

                    <h4 class="fs-5">
                        Propreté / Hygiène
                        <span class="grass-2" v-if="(rating.proprete.note != 0)"><i class="fa-solid fa-check"></i></span>
                        <span class="fs-07 grape-2" v-else>obligatoire</span>
                    </h4>

                    <div class="my-3">
                        <span 
                            v-for="n in 5"
                            @click="defineNoteProprete(n)"
                        >
                            <i :class="{
                                'sunflo-2': rating.proprete.note >= n,
                                'lg-3': rating.proprete.note < n, 
                            }" class="fa-solid fa-star fa-fw fa-2x "></i>
                        </span>
                        <span class="fs-2 sunflo-3 ms-1">{{ rating.proprete.note }}</span>
                        <span class="dg-2"> / 5</span>
                    </div>

                    <div class="form-floating">
                        <textarea 
                            id="proprete" 
                            name="proprete" 
                            v-model="rating.proprete.com"
                            class="form-control" 
                            :class="{
                                'border-grape-2': rating.proprete.note != 0 && rating.proprete.note != 5 && rating.proprete.com.length < 10
                            }" 
                            style="height: 100px"></textarea>
                        <label for="proprete">
                            Commentaire 
                            <small class="aqua-2" v-if="(rating.proprete.note == 5)">optionnel</small>
                            <small class="grape-2" v-else-if="(rating.proprete.note != 5 && rating.proprete.com.length < 10)">obligatoire - min 10 caractères</small>
                            <small class="grass-2" v-else><i class="fa-solid fa-check fa-fw"></i></small>
                        </label>
                        <small class="grape-1 mt-1" v-if="(rating.proprete.note != 0 && rating.proprete.note != 5 && rating.proprete.com.length < 10)">Une note inférieur à 5 est possible, mais elle doit être justifiée afin que nous puissions nous améliorer</small>
                        <small class="grass-3 mt-1" v-else-if="(rating.proprete.note != 0 && rating.proprete.note != 5 && rating.proprete.com.length >= 10)">Merci. Nous allons prendre en considération votre remarque.</small>
                    </div>

                </div>
            </div>
        </div>

        <!-- Cadre / Lieu Obligatoire -->
        <div class="col-12" v-if="(!rating.isSend && !passage.isRated)">
            <div class="card br-10 p-3">
                <div class="card-body">

                    <h4 class="fs-5">
                        Cadre / Lieu
                        <span class="grass-2" v-if="(rating.lieu.note != 0)"><i class="fa-solid fa-check"></i></span>
                        <span class="fs-07 grape-2" v-else>obligatoire</span>
                    </h4>

                    <div class="my-3">
                        <span 
                            v-for="n in 5"
                            @click="defineNoteLieu(n)"
                        >
                            <i :class="{
                                'sunflo-2': rating.lieu.note >= n,
                                'lg-3': rating.lieu.note < n, 
                            }" class="fa-solid fa-star fa-fw fa-2x "></i>
                        </span>
                        <span class="fs-2 sunflo-3 ms-1">{{ rating.lieu.note }}</span>
                        <span class="dg-2"> / 5</span>
                    </div>

                    <div class="form-floating">
                        <textarea 
                            id="lieu" 
                            name="lieu" 
                            v-model="rating.lieu.com"
                            class="form-control" 
                            :class="{
                                'border-grape-2': rating.lieu.note != 0 && rating.lieu.note != 5 && rating.lieu.com.length < 10
                            }" 
                            style="height: 100px"></textarea>
                        <label for="proprete">
                            Commentaire 
                            <small class="aqua-2" v-if="(rating.lieu.note == 5)">optionnel</small>
                            <small class="grape-2" v-else-if="(rating.lieu.note != 5 && rating.lieu.com.length < 10)">obligatoire - min 10 caractères</small>
                            <small class="grass-2" v-else><i class="fa-solid fa-check fa-fw"></i></small>
                        </label>
                        <small class="grape-1 mt-1" v-if="(rating.lieu.note != 0 && rating.lieu.note != 5 && rating.lieu.com.length < 10)">Une note inférieur à 5 est possible, mais elle doit être justifiée afin que nous puissions nous améliorer</small>
                        <small class="grass-3 mt-1" v-else-if="(rating.lieu.note != 0 && rating.lieu.note != 5 && rating.lieu.com.length >= 10)">Merci. Nous allons prendre en considération votre remarque.</small>
                    </div>

                </div>
            </div>
        </div>

        <div class="col-12" v-if="(!rating.isSend && !passage.isRated)">
            <hr>
        </div>

        <div class="col-12 p-3" v-if="(!rating.isSend && !passage.isRated)">
            <h4 class="fs-5">
                Avis global <small class="dg-1">calcul automatique</small>
            </h4>
            <div class="my-3">
                <span 
                    v-for="n in 5"
                >
                    <i 
                        :class="{
                            'sunflo-2': rating.global.note >= n,
                            'lg-3': rating.global.note < n, 
                        }" 
                        class="fa-regular fa-star fa-fw fa-2x"></i>
                </span>
                <span class="fs-2 sunflo-3 ms-1">{{ rating.global.note }}</span>
                <span class="dg-2"> / 5</span>
            </div>
            <div class="form-floating">
                <textarea 
                    id="global" 
                    name="global" 
                    v-model="rating.global.com"
                    class="form-control"
                    style="height: 200px"></textarea>
                <label for="global">
                    Commentaire <small class="aqua-2">optionnel et publique</small>
                </label>
                <small class="dg-1">Ce commentaire apparaitra sur notre site</small>
            </div>
        </div>


        <div class="col-12 d-grid gap-2 d-md-flex justify-content-end" v-if="(!rating.isSend && !passage.isRated)">
            <button 
                class="btn btn-primary"
                :class="{
                    'disabled': !rating.isSendable
                }"
                @click="sendRating()"
            >
                <span v-if="!rating.isSendable">Évaluez chaque catégorie pour pouvoir valider</span>
                <span v-else>Valider mon avis</span>
            </button>
        </div>

        <div class="col-12" v-if="(rating.isSend && passage.isRated)">
            <div class="card br-10 p-4">
                <div class="card-body">
                    <p class="fs-5 mb-0"><i class="fa-regular fa-circle-check fa-fw grass-2"></i> Avis posté</p>
                </div>
            </div>
        </div>

        <div class="col-12" v-if="(!rating.isSend && passage.isRated)">
            <div class="card br-10 p-4">
                <div class="card-body">
                    <p class="fs-5 mb-0"><i class="fa-regular fa-face-smile-wink fa-fw blue-2"></i> Avis déjà posté pour ce passage</p>
                    <!-- <div class="my-3">
                        <span v-for="n in 5">
                            <i 
                                :class="{
                                    'sunflo-2': rating.global.note >= n,
                                    'lg-3': rating.global.note < n, 
                                }" 
                                class="fa-regular fa-star fa-fw fa-2x"></i>
                        </span>
                        <span class="fs-2 sunflo-3 ms-1">{{ rating.global.note }}</span>
                        <span class="dg-2"> / 5</span>
                    </div>
                    <p v-if="rating.global.com">{{ rating.global.com }}</p> -->
                </div>
            </div>
        </div>

    </div>

</template>

<script setup>

    import { reactive, watch } from "vue";
    import axios from 'axios'


    /**
     * Initialisation du composant
     */
    const props = defineProps(['passageId'])
    console.log(props.passageId);

    const passage = reactive({
        serviceName: '',
        customer: {
            id: '',
        },
        employee: {
            id: '',
            firstname: '',
        },
        createdAt : '',
        isRated: false
    })


    // Info du passage
    axios.get('/api/passages/' + props.passageId)
        .then(response => {
            //console.log(response);
            
            // Informations du passage
            passage.id = response.data.id;
            passage.serviceName = response.data.serviceName; 
            passage.customer.id = response.data.customer.id;
            passage.createdAt = response.data.createdAt;

            // Employée
            passage.employee.id = response.data.employee.id;
            passage.employee.firstname = response.data.employee.user.firstname;
            
            passageIsRated(passage.id)
        });

    function passageIsRated(passageId) {
        axios.get('/api/customer_ratings?passage='+ passageId)
            .then(response => {
                //console.log('IsAlreadyRated', response);
                
                // Si une réponse alors déjà noté
                if (response.data["hydra:totalItems"] === 1) {
                    passage.isRated = true;
                    rating.global.note = response.data["hydra:member"][0].noteTotale;
                    rating.global.com = response.data["hydra:member"][0].comGlobal;
                } else {
                    passage.isRated = false;
                }
                
            });
    }


    /**
     * Gestion de l'avis
     */
    const rating = reactive({
        global: {
            note: 0,
            com: ''
        },
        accueil: {
            note: 0,
            com: ''
        },
        ecoute: {
            note: 0,
            com: ''
        },
        qualite: {
            note: 0,
            com: ''
        },
        proprete: {
            note: 0,
            com: ''
        },
        lieu: {
            note: 0,
            com: ''
        },
        isSend: false,
        isSendable: false
    })

    function defineNoteAccueil(note) {
        rating.accueil.note = note;
    }
    function defineNoteEcoute(note) {
        rating.ecoute.note = note;
    }
    function defineNoteQualite(note) {
        rating.qualite.note = note;
    }
    function defineNoteProprete(note) {
        rating.proprete.note = note;
    }
    function defineNoteLieu(note) {
        rating.lieu.note = note;
    }

    // Mise à jour du total en temps réel
    watch(rating, async (newRating, oldRating) => {

        // Constitution d'une liste de note
        const  arrayOfNotes = [];
        rating.accueil.note != 0 && rating.accueil.note > 0 && rating.accueil.note < 6 ? arrayOfNotes.push(rating.accueil.note) : '';
        rating.ecoute.note != 0 && rating.ecoute.note > 0 && rating.ecoute.note < 6  ? arrayOfNotes.push(rating.ecoute.note) : '';
        rating.qualite.note != 0 && rating.qualite.note > 0 && rating.qualite.note < 6  ? arrayOfNotes.push(rating.qualite.note) : '';
        rating.proprete.note != 0 && rating.proprete.note > 0 && rating.proprete.note < 6  ? arrayOfNotes.push(rating.proprete.note) : '';
        rating.lieu.note != 0 && rating.lieu.note > 0 && rating.lieu.note < 6  ? arrayOfNotes.push(rating.lieu.note) : '';
        // console.log('Array of note : ', arrayOfNotes)
        
        // Calcul de la note globale par itération sur les notes saisies
        let totalList = 0;
        for (let i = 0; i < arrayOfNotes.length; i++) {
            totalList += arrayOfNotes[i];
        } 

        // If float, 1 seul décimal
        rating.global.note = (totalList / arrayOfNotes.length) % 1 === 0 ? totalList / arrayOfNotes.length : (totalList / arrayOfNotes.length).toFixed(1);

    })

    // Contraintes de validation avant envoi
    watch(rating, async (newRating, oldRating) => {
        
        // Toutes les notes sont supérieures à 0 et inférieures à 6
        const isAllRated = rating.accueil.note > 0 && rating.accueil.note < 6 && rating.ecoute.note > 0 && rating.ecoute.note < 6 && rating.qualite.note > 0 && rating.qualite.note < 6 && rating.proprete.note > 0 && rating.proprete.note < 6 && rating.lieu.note > 0 && rating.lieu.note < 6 && rating.global.note > 0 && rating.global.note < 6 ? true : false;
        
        // Toutes les notes sont soit justifiées soit égales à 5
        const isAllJustified = 
            (( rating.accueil.note != 0 && rating.accueil.note < 5 && rating.accueil.com.length >= 10 ) || rating.accueil.note == 5 ) && 
            (( rating.ecoute.note != 0 && rating.ecoute.note < 5 && rating.ecoute.com.length >= 10 ) || rating.ecoute.note == 5 ) && 
            (( rating.qualite.note != 0 && rating.qualite.note < 5 && rating.qualite.com.length >= 10 ) || rating.qualite.note == 5 ) && 
            (( rating.proprete.note != 0 && rating.proprete.note < 5 && rating.proprete.com.length >= 10 ) || rating.proprete.note == 5 ) && 
            (( rating.lieu.note != 0 && rating.lieu.note < 5 && rating.lieu.com.length >= 10 ) || rating.lieu.note == 5 ) ? true : false;

        // Si toutes les conditions sont rénuies alors envoi possible
        rating.isSendable = isAllRated && isAllJustified ? true : false;

    })


    /**
     * Envoi de l'avi en BDD
     */
    async function sendRating() {

        // Conversion en string pour format décimal en bdd
        let noteGlobale = rating.global.note.toString();
        // console.log("Note globale :", noteGlobale)
        // console.log(typeof noteGlobale)

        await axios.post('/api/customer_ratings', {
            "passage": '/api/passages/' + passage.id,
            "service":  passage.serviceName,
            "noteAccueil": rating.accueil.note,
            "noteEcoute": rating.ecoute.note,
            "noteQualite": rating.qualite.note,
            "noteProprete": rating.proprete.note,
            "noteLieu": rating.lieu.note,
            "noteTotale": noteGlobale,
            "comAccueil": rating.accueil.com ? rating.accueil.com : null,
            "comEcoute": rating.ecoute.com ? rating.ecoute.com : null,
            "comQualite": rating.qualite.com ? rating.qualite.com : null,
            "comProprete": rating.proprete.com ? rating.proprete.com : null,
            "comLieu": rating.lieu.com ? rating.lieu.com : null,
            "comGlobal": rating.global.com ? rating.global.com : null,
            "customer": '/api/users/' + passage.customer.id,
            "employee": '/api/employees/' + passage.employee.id,
        })
        .then(response => {
            rating.isSend = true;
            passage.isRated = true;
        })
        .catch(error => {
            console.log(error);
        })
    }

</script>

<style>

</style>
