<template>
    <h4>Vue Section</h4>
    <div>Hello {{ name }} !</div>

    <div class="card p-5">
        <p>card</p>
        <p>{{ chuck.joke }}</p>

        <div class="d-flex">
            <button class="btn btn-primary" @click="getNewJoke">New Joke</button>
        </div>
    </div>

    <p><span v-html="retourGiftCard"></span></p>
</template>

<script setup>

    import { ref, reactive } from "vue";
    import axios from 'axios'


    console.log('Inside')

    const name = ref('Nico')


    const chuck = reactive({
        joke: 'Click below to show a Chuck Norris joke !'
    })

    const getNewJoke = () => {
        axios.get('https://api.chucknorris.io/jokes/random')
        .then(response => {
            console.log(response.data)
            chuck.joke = response.data.value
        })
    }


    const retourGiftCard = ref('')
    axios.get('/api/gift_cards')
        .then(response => {
            console.log('GiftCard', response)
            retourGiftCard.value = response.data 
        })

</script>
